import $ from "jquery";

$(document).on('change', '[data-remote-on]', function () {
    // console.log("remote switch");
    var checked = $(this).is(":checked")
    if (checked == true) {
        var url = $(this).attr('data-remote-on')
    } else {
        var url = $(this).attr('data-remote-off')
    }
    $.ajax({
        url: url,
        // data: { query: query },
        type: 'POST',
        dataType: "script",
        success: function (data) {
            console.log(data);
        }
    });
})