import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    console.log("🔌 select2 connected!");
  }

  initialize() {
    console.log(this);
    const element = this.element;
    const $element = window.$(element);

    const tags = ($element.attr('data-tags') != undefined) ? true : false;
    const placeHolder = ($element.attr('data-placeholder') != undefined) ? $element.attr('data-placeholder') : 'Select an option';
    console.log("tags", tags)
    
    // 🤔 
    // setTimeout(function() {
      if ($element.attr('data-path') != undefined) {
        $element.select2({
          placeholder: placeHolder,
          closeOnSelect: true,
          tags: tags,
          minimumInputLength: $element.attr('data-min') || 0,
          ajax: {
            url: $element.attr('data-path'),
            dataType: 'json',
            delay: 400,
          }
        }).on('select2:select', function () {
            let event = new Event('change', { bubbles: true }) // fire a native event
            element.dispatchEvent(event);
        }).on('select2:unselect', function () {
            let event = new Event('change', { bubbles: true }) // fire a native event
            element.dispatchEvent(event);
        });
      } else {
        $element.select2({
          placeholder: placeHolder,
          closeOnSelect: true,
          tags: tags,
          minimumInputLength: $element.attr('data-min') || 0,
        }).on('select2:select', function () {
          let event = new Event('change', { bubbles: true }) // fire a native event
          element.dispatchEvent(event);
        }).on('select2:unselect', function () {
          let event = new Event('change', { bubbles: true }) // fire a native event
          element.dispatchEvent(event);
        }).on('select2:open', function () {
          document.querySelector('.select2-container--open .select2-search__field').focus();
        });
      }
    // },50)
  }


  resetToDefault() {
      const element = this.element;
      const $element = window.$(element);
      // const $element = window.$('payer_select');
      console.log('reset to default called.');
      $element.val(null).trigger("change");
  }

}