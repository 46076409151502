/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import "core-js/stable";
import "regenerator-runtime/runtime";

import "stylesheets/application.scss";

import LZString from "lz-string";
window.LZString = LZString;

// require("jquery")
import $ from "jquery";
window.jQuery = $;
global.jQuery = $;
window.$ = $;
global.$ = $;

import CookiesEuBanner from "cookies-eu-banner";
window.CookiesEuBanner = CookiesEuBanner;

// import {Turbo} from "@hotwired/turbo-rails"
// window.Turbo = Turbo

require("@rails/ujs").start();
require("turbolinks").start();
require("@fancyapps/fancybox");

import "controllers";

// import Masonry from "masonry-layout";
import ahoy from "ahoy.js";
// import tippy from "tippy.js";
import store from "storejs";
import "slick-carousel";
import ClipboardJS from "clipboard";
import Fuse from "fuse.js";
// import select2 from "select2";
// window.select2 = select2;

// import "autogrow";
import "packs/remote-switch";
import Sticky from "sticky-js";

import("main/search-suggestions.js");
import("main/search.js");
import { isSafariMobile, isSafariDesktop } from "../main/systemInfoService";

// import getAllUrlParams from 'main/geturlparams.js';
global.getAllUrlParams = getAllUrlParams;

global.isSafariMobile = isSafariMobile;
global.isSafariDesktop = isSafariDesktop;

global.store = store;
global.ahoy = ahoy;
global.Fuse = Fuse;
// global.tippy = tippy;
// global.Masonry = Masonry;

window.Sticky = Sticky;

// $(document).ready(function(){
// window.addEventListener('load', function () {
//   console.log("🍓load")
//   initAll();
// });

window.addEventListener("turbolinks:load", function () {
  console.log("🍓turbolinks:load");
  initAll();
  accordionBind();
});

function initAll() {
  $("body").removeClass("no-js");

  $("[data-offer-open]").on("click", function () {
    var offerID = $(this).data("offer-open");
    showOffer(offerID);
  });
  $("[data-offer-open-mobile]").on("click", function () {
    if ($(window).width() <= 768) {
      var offerID = $(this).data("offer-open-mobile");
      showOffer(offerID);
    }
  });

  // initSelect2();
  initHorizontaScroll();

  var sticky = new Sticky(".sticky");

  if (getAllUrlParams().offer != undefined) {
    showOffer(getAllUrlParams().offer);
  }
  if (getAllUrlParams().all_coupons != undefined) {
    showAllCoupons(getAllUrlParams().all_coupons);
  }
  if (getAllUrlParams().sign_up != undefined) {
    showStoreSignup(getAllUrlParams().sign_up);
  }

  initLazyBackgrounds();

  $("[data-ahoy]").on("click", function () {
    var name = $(this).data("ahoy");
    var properties = $(this).data("ahoy-properties");
    console.log(properties);
    ahoy.track(name, properties);
  });

  $("[data-toggle-content]").on("change", function () {
    console.log("toggle");
    var content = $(this).attr("data-toggle-content");
    $('[data-content-for="' + content + '"]').each(function (index, el) {
      console.log(el);
      var group = $(el).attr("data-content-group");
      $('[data-content-group="' + group + '"]').addClass("hide");
      $(el).removeClass("hide");
      $(el).find(".demo-slider").slick("slickGoTo", 0).slick("refresh");
      $(el).find(".slider-text").slick("slickGoTo", 0).slick("refresh");
    });
  });

  // console.log("%c main.js loaded", 'font-size: 32px');

  sizedPlaceholders();
  $(window).resize(function () {
    sizedPlaceholders();
  });

  function sizedPlaceholders() {
    // if ($(window).width() <= 768) {
    if ($(window).width() <= 1300) {
      $("[data-placeholder-mobile]").each(function (index, el) {
        $(el).attr("placeholder", el.getAttribute("data-placeholder-mobile"));
      });
      // console.log("mobile placeholders");
    } else {
      $("[data-placeholder-desktop]").each(function (index, el) {
        $(el).attr("placeholder", el.getAttribute("data-placeholder-desktop"));
      });
      // console.log("desktop placeholders");
    }
  }

  $(document).on("click", "[data-open-chat]", function (e) {
    e.preventDefault();
    if (window.fcWidget.isOpen() != true) {
      console.log("opening widget");
      window.fcWidget.open();
    } else {
      // window.fcWidget.close();
      // window.fcWidget.open();
      console.log("widget is open");
    }
  });

  $(document).on("click", "[data-fancybox-close-parent]", function () {
    // Broken, probably because of js deger
    // parent.jQuery.fancybox.getInstance().close();
  });

  initFancybox();

  $.extend($.fancybox.defaults, {
    touch: false,
    afterLoad: function () {
      // initJS();
      initFancybox();
    },
    beforeLoad: function () {
      initFancybox();
    },
  });

  $(".add-image input[type='file']").change(function () {
    showImage(this);
  });

  $(".add-avatar #user_avatar").change(function () {
    showImage(this);
  });

  $(".top-bar .avatar").on("mouseenter", function () {
    var dropdown = $(this).siblings(".dropdown-menu");
    dropdown.removeClass("hide");
    $(".top-bar").on("mouseleave", function () {
      dropdown.addClass("hide");
    });
  });

  initTabs();

  $(document).on("click", "[data-reset-filters]", function (e) {
    e.preventDefault();
    $("#categories-picker").find('input[type="checkbox"]').removeAttr("checked");
    recalculateFilters();
    applyFilters();
  });

  $(document).on("click", ".filters-panel-content.is-visible a", function () {
    // mobilePanelScrollable()
    mobilePanelScrollable();
  });

  function mobilePanelScrollable() {
    if ($(window).width() <= 768) {
      $(".filters-panel-content.is-visible, .mobile-search-panel.is-visible").each(function (index, el) {
        // console.log($(el).innerHeight());
        if ($(el).innerHeight() >= $(window).height()) {
          $(el).addClass("scrollable");
        } else {
          $(el).removeClass("scrollable");
        }
      });
    }
  }

  // Submit form on click
  $(document).on("click", "[data-submit]", function (e) {
    console.log("Submitting");
    e.preventDefault();
    $($(this).attr("data-submit")).submit();
  });

  // Submit form on click
  $(document).on("click", "[data-clear-field]", function (e) {
    e.preventDefault();
    $($(this).data("clear-field")).val("");
  });

  // categories-picker
  $("#categories-picker").on("change", 'input[type="checkbox"]', function () {
    // automatically close the modal after checking one, I don't like this idea but well 🤷‍
    if ($(this).is(":checked")) {
      closeAllModals();
    }
    delayAction(function () {
      applyFilters();
    }, 1000);
    recalculateFilters();
  });

  function recalculateFilters() {
    var categories_count = $("#categories-picker").find('input[type="checkbox"]:checked').length;
    $("[data-counter-categories-picked]").html(categories_count + " selected");
  }

  function applyFilters() {
    var categories = [];
    $("#categories-picker")
      .find('input[type="checkbox"]:checked')
      .each(function () {
        categories.push($(this).val());
      });
    updateSmartListingFilter($("#categories"), categories.join(","));
  }

  // price slider
  var handle = $("#custom-handle");
  var price_min = $("[data-filter-price-min]");
  var price_max = $("[data-filter-price-max]");
  var slider = $("#price-slider");

  var price_range_field = $("#price_range");

  if (slider.length > 0) {
    var price_range_min = price_range_field.val().split("..")[0] || 0;
    var price_range_max = price_range_field.val().split("..")[1] || 800;
    slider.slider({
      range: true,
      min: 0,
      max: 1000,
      values: [price_range_min, price_range_max],
      slide: function (event, ui) {
        price_min.html(ui.values[0]);
        price_max.html(ui.values[1]);
      },
      stop: function (event, ui) {
        updateSmartListingFilter(price_range_field, ui.values[0] + ".." + ui.values[1]);
      },
      change: function (event, ui) {
        updateSmartListingFilter(price_range_field, ui.values[0] + ".." + ui.values[1]);
      },
      create: function () {
        price_min.html($(this).slider("values", 0));
        price_max.html($(this).slider("values", 1));
      },
    });
  }

  // $(".offer-stripe .expand-link").on("click", function (e) {
  //   e.preventDefault();
  //   var coupon = $(this).parents(".offer-stripe");
  //   var expandable = coupon.find(".expandable-content");
  //   if (coupon.hasClass("expanded")) {
  //     $(this).removeClass("expanded");
  //     coupon.removeClass("expanded");
  //     expandable.removeClass("expanded");
  //   } else {
  //     $(this).addClass("expanded");
  //     expandable.addClass("expanded");
  //     coupon.addClass("expanded");
  //   }
  // });

  $("a[data-filter-notifications]").on("click", function (e) {
    e.preventDefault();
    var filter = $(this).data("filter-notifications");
    $(this).siblings("a").removeClass("active");
    $(this).addClass("active");
    if (filter == false) {
      $(".notification-list").find(".notification-list-item").removeClass("hide");
      console.log("resetting the filters");
    } else {
      $(".notification-list").find(".notification-list-item").addClass("hide");
      $(".notification-list-item.type-" + filter).removeClass("hide");
      console.log(filter + " filter applied");
    }
  });

  $(".products-list.selectable").on("click", ".content-list-item", function () {
    var selected_inspirations = $("#selected_inspirations").val().split(",");
    var selected = $(this).data("inspiration-id");
    if ($(this).hasClass("selected")) {
      var index = selected_inspirations.indexOf("" + selected);
      if (index > -1) {
        selected_inspirations.splice(index, 1);
      }
      $("#selected_inspirations").val(selected_inspirations);
      $(this).removeClass("selected");
    } else {
      selected_inspirations.push("" + selected);
      selected_inspirations = clean_array(selected_inspirations);
      $("#selected_inspirations").val(selected_inspirations.join(","));
      $(this).addClass("selected");
    }
  });

  $(document).on("click", ".callout-close", function (e) {
    e.preventDefault();
    $(this).parents(".callout").addClass("hide");
  });

  $(document).on("click", ".scroll-to, .abc-scroller a", function () {
    var el = $($.attr(this, "href"));
    $("html, body").animate(
      {
        scrollTop: el.offset().top - 80 - 15, // 80 height of the top bar
      },
      500
    );
    el.find("[data-highlight-on-scroll]").addClass("highlighted");
    setTimeout(function () {
      el.find("[data-highlight-on-scroll]").removeClass("highlighted");
    }, 1000);
    return false;
  });

  $(".notification-list").on("click", ".notification-list-item-close", function (e) {
    e.preventDefault();
    $(this).parents(".notification-list-item").css("opacity", "0.3");
  });

  $(document).on("click", "[data-toggle]", function (e) {
    e.preventDefault();
    var elem = $(this).data("toggle");
    $(elem).toggleClass("hide");
  });

  // product item hover
  $(".content-list-item").on("mouseenter", function () {
    // $('.content-list-item').find('.tags').addClass('hide')
    $(this).find(".tags").removeClass("hide");
  });
  $(".content-list-item").on("mouseleave", function () {
    $(this).find(".tags").addClass("hide");
  });

  $(".submit-search-form").on("click", function (e) {
    e.preventDefault();
    $(this).parents("form").submit();
  });

  //parse links
  $(".parse-links").each(function (index, el) {
    var str = $(el).html();
    var regexp = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gi;
    var matches_array = str.match(regexp);
    $.each(matches_array, function (index, url) {
      default_protocol = "http://";
      if (str.toLowerCase().indexOf("http") >= 0) {
        default_protocol = "";
      }
      str = str.replace(url, '<a href="' + default_protocol + url + '">' + url + "</a>");
    });
    //console.log(matches_array);
    //console.log(str);
    $(el).html(str);
  });

  // show more users
  $(document).on("click", ".view-more", function (e) {
    e.preventDefault();
    $(this).addClass("hide");
    $(this).parents(".user-card-list").removeClass("collapsed");
  });

  // clipboard
  var clipboard = new ClipboardJS(".clipboard");
  clipboard.on("success", function (e) {
    // e.preventDefault();
    if ($(e.trigger).attr("data-copied-text")) {
      var copiedText = $(e.trigger).attr("data-copied-text");
    } else {
      var copiedText = "Copied!";
    }
    $(e.trigger).text(copiedText);
    $(e.trigger).addClass("copied");
  });

  $(document).on("click", ".invite-options .copy-link", function (e) {
    e.preventDefault();
  });

  var inviteClipboard = new ClipboardJS(".invite-options .copy-link");
  inviteClipboard.on("success", function (e) {
    $(".invite-options .copy-link").find("span").html("Copied!");
  });
  var inviteClipboard = new ClipboardJS(".text-clipboard");
  inviteClipboard.on("success", function (e) {
    console.log("Copied to clipboard");
  });

  // $("textarea").autogrow({
  //   speed: 100,
  // });

  reInitMasonry();

  $(document).on("input", "#assign-category-search", function () {
    var query = $(this).val();
    if (query.length >= 2) {
      $.ajax({
        url: "/categories_list",
        data: { query: query },
        type: "GET",
        success: function (data) {
          console.log(data);
          if (data) {
            $(".assign-category-search-results").html(data);
            autoSelectFirstResult();
          } else {
          }
        },
      });
    } else {
      $(".assign-category-search-results").html("");
    }
  });

  function autoSelectFirstResult() {
    if ($(".assign-category-search-results .category").length > 0) {
      var first = $(".assign-category-search-results .category").eq(0);
      var category_id = first.data("category-id");
      first.addClass("selected");
      $("#form_category_id").val(category_id);
      console.log("First result autoselected");
    }
  }

  $(document).on("click", ".assign-category-search-results .category", function (e) {
    e.preventDefault();
    var category_id = $(this).data("category-id");
    if ($(this).hasClass("selected")) {
      $(this).removeClass("selected");
      $("#form_category_id").val("");
    } else {
      $(".assign-category-search-results .category").removeClass("selected");
      $(this).addClass("selected");
      $("#form_category_id").val(category_id);
    }
  });

  // Show product examples for breadcrumbs
  $(document).on("click", ".show-example-products", function (e) {
    e.preventDefault();
    $(this).parents("tr").find(".example-products").removeClass("hide");
  });

  // toggle
  $(document).on("click", "[data-toggle]", function (e) {
    e.preventDefault();
    var el = $(this).data("toggle");
    var toggleClass = $(el).data("toggle-class");
    toggleClass = toggleClass ? toggleClass : "hide";
    $(el).toggleClass(toggleClass);
  });

  // rich select
  $(document).on("click", ".rich-select .rich-select-item", function () {
    var val = $(this).data("select-value");
    var richSelect = $(this).parents(".rich-select");
    var input = richSelect.data("fill-input");
    console.log(val);
    console.log(richSelect);
    console.log(input);
    $(input).val(val);
    richSelect.find(".rich-select-item").removeClass("selected");
    $(this).addClass("selected");
  });
}

// Optimistic buttons
$(document).on("click", "a[data-follow]", function (e) {
  var id = $(this).data("follow");
  var buttons = $('[data-follow="' + id + '"]');
  if ($(this).hasClass("is-not-following")) {
    buttons.each(function (index, el) {
      if (!$(el).hasClass("no-text")) {
        $(el).html("Following");
      }
      $(el).removeClass("is-not-following");
      $(el).addClass("is-following");
      // $(el).attr('href', $(el).attr('href').replace('/follow', '/unfollow'))
    });
    // update counters
    var counters = $('[data-followers-counter-id="' + id + '"]');
    counters.each(function (index, el) {
      var current_counter = parseInt($(el).html());
      $(el).html(current_counter + 1);
    });
  } else if ($(this).hasClass("is-following")) {
    buttons.each(function (index, el) {
      if (!$(el).hasClass("no-text")) {
        $(el).html("Follow");
      }
      $(el).removeClass("is-not-following");
      $(el).addClass("is-following");
      // $(el).attr('href', $(el).attr('href').replace('/follow', '/unfollow'))
    });
    // update counters
    var counters = $('[data-followers-counter-id="' + id + '"]');
    counters.each(function (index, el) {
      var current_counter = parseInt($(el).html());
      $(el).html(current_counter - 1);
    });
  }
});

// });

$(document).on("click", ".comment-reply-action", function (e) {
  e.preventDefault();
  $(this).addClass("hide");
  var formID = $(this).attr("href");
  $(formID).removeClass("hide");
  setTimeout(function () {
    $(formID).focus();
  }, 100);
});

$(document).on("click", "[data-source]", function () {
  var source = $(this).data("source");
  var store = $(this).data("store-subscribe");
  document.cookie = "sign_up_source=" + source + ";path=/";
  if (store !== undefined) {
    document.cookie = "store_subscribe=" + store + ";path=/";
  }
});

$(document).on("click", "[data-save-cookie]", function () {
  var cookieName = $(this).data("save-cookie");
  document.cookie = cookieName + "=true;path=/";
});

// $(document).on("click", ".mobile-menu-opener", function (e) {
//   e.preventDefault();
//   $(".top-bar").toggleClass("white");
//   $(this).toggleClass("opened");
//   $(".mobile-menu").toggleClass("opened");
// });

// $(document).on('click', '.tag-modal-slide-prev', function(e){
// e.preventDefault();
// console.log("asdas");
// $('.tag-modal-slides').slick('slickPrev');
// })
// $(document).on('click', '.tag-modal-slide-next', function(e){
// e.preventDefault();
// console.log("asdas");
// $('.tag-modal-slides').slick('slickNext');
// })

// sliding panels
setTimeout(function () {
  // to prevent showing it for a few ms
  $(document).find(".sliding-menu-content").addClass("init");
}, 2000);
$(document).on("click", ".sliding-menu-button,.sliding-menu-fade-screen,.sliding-menu-close, .modal-fade-screen, [data-toggle-panel]", function (e) {
  e.preventDefault();
  if ($(this).data("toggle-panel")) {
    console.log($(this).data("toggle-panel"));
    $('[data-panel!="' + $(this).data("toggle-panel") + '"]').removeClass("is-visible");
    $('[data-panel="' + $(this).data("toggle-panel") + '"]').toggleClass("is-visible");
  } else {
    $(".sliding-menu-content,.sliding-menu-fade-screen").toggleClass("is-visible");
    if ($(this).hasClass("search-menu")) {
      setTimeout(function () {
        $('.sliding-menu-content input[type="search"]').focus();
      }, 300);
    }
  }
});

// accordion menu
window.accordionBind = accordionBind;
function accordionBind() {
  console.log("accordionBind");
  const accordionTriggers = document.querySelectorAll(".js-accordion-trigger");
  accordionTriggers.forEach((trigger) => {
    trigger.addEventListener("click", function (e) {
      const submenu = this.parentNode.querySelector(".submenu");
      submenu.style.display = submenu.style.display === "block" ? "none" : "block";
      this.parentNode.classList.toggle("is-expanded");
      e.preventDefault();
    });
  });
}

// show uploaded image preview
function showImage(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();
    var label = $(input).parents("label.add-image, label.add-avatar");
    // var label = $(input).parents('label')
    reader.onload = function (e) {
      label.addClass("has_photo");
      label.css("background-image", 'url("' + e.target.result + '"');
    };
    reader.readAsDataURL(input.files[0]);
  }
}

// function update smart_listing filer
function updateSmartListingFilter(input, value) {
  $(input).val(value);
  $("form.smart-listing-controls").submit();
}

// Topbar on homepage
$(document).on("scroll", function () {
  if ($(".page-pages-index .hero").length > 0) {
    var hero_bottom = $(".hero").outerHeight();
    var scrolled = $(document).scrollTop();
    // console.log("hero_bottom:" + hero_bottom);
    // console.log("scrolled:"+ scrolled);
    if (scrolled > hero_bottom) {
      // $('.hide-while-on-top').removeClass('hide')
      $("body").removeClass("hero-seamless");
    } else {
      // $('.hide-while-on-top').addClass('hide')
      $("body").addClass("hero-seamless");
    }
    if (scrolled > 0 && scrolled < hero_bottom) {
      $(".top-bar").addClass("not-on-top");
    } else {
      $(".top-bar").removeClass("not-on-top");
    }
  }
});

$(document).on("submit", "#share_via_email", function () {
  $(this).find('input[type="submit"]').val("Sending").attr("disabled", true);
  setTimeout(function () {
    $(this).find('input[type="submit"]').val("Send").removeAttr("disabled");
  }, 10000);
});

$(document).on("click", ".close-notice, .floating-notice .modal-close-button", function (e) {
  e.preventDefault();
  $(this).parents(".notice").remove();
  $(this).parents(".top-notice").remove();
  $(this).parents(".floating-notice").remove();
});
$(document).on("click", "[data-closes-notice], .notice-actions a", function (e) {
  $(this).parents(".floating-notice").remove();
});

// Client side form prevention from submitting more than once
$(document).on("submit", "form.submit-once", function () {
  $(this).find('input[type="submit"]').attr("disabled", "true");
});

function clean_array(test_array) {
  var index = -1,
    arr_length = test_array ? test_array.length : 0,
    resIndex = -1,
    result = [];

  while (++index < arr_length) {
    var value = test_array[index];

    if (value) {
      result[++resIndex] = value;
    }
  }

  return result;
}

$(document).on("click", "[data-show-more]", function (e) {
  e.preventDefault();
  var target = $('[data-has-more="' + $(this).data("show-more") + '"]'); //.last();
  // .last() because sometimes featherlight duplicates the modal content so we need to use only the modal one
  var showCount = target.data("show-count");

  for (var i = 0; i < showCount; i++) {
    target.children('[class*="hide"]').eq(0).removeClass("hide hide-on-small hide-on-medium");
  }
  if (target.children('[class*="hide"]').length == 0) {
    $(this).parents(".view-more-container").html("");
    $(this).remove();
  }
  reInitMasonry();
});

// toggle
$(document).on("click", "[data-toggle]", function (e) {
  e.preventDefault();
  var el = $(this).data("toggle");
  var toggleClass = $(el).data("toggle-class");
  toggleClass = toggleClass ? toggleClass : "hide";
  $(el).toggleClass(toggleClass);
});

setInterval(function () {
  reInitMasonry();
}, 1000);

function reInitMasonry() {
  if ($(".masonry").length > 0) {
    $(".masonry").each(function (index, el) {
      var msnry = new Masonry(el, {
        horizontalOrder: true,
      });
    });
  }
}

$(document).on("focus", ".sitewide-search:not(.initialized)", function () {
  $.ajax({
    url: "/typeahead",
    type: "GET",
    dataType: "script",
  }).done(function () {
    console.log("🐎🐎🐎🐎typeahead initialized");
    $(".sitewide-search").addClass("initialized");
    $(".sitewide-search").trigger("input");
    setTimeout(function () {
      // back-up trigger :)
      $(".sitewide-search").trigger("input");
    }, 100);
  });
});

// Open all external links in a new window
addEventListener(
  "click",
  function (event) {
    var el = event.target;
    if (el.tagName === "A" && !el.isContentEditable && el.host !== window.location.host) {
      if ($(el).parents(".trix-content").length) {
        el.setAttribute("target", "_blank");
      }
    }
  },
  true
);

function getAllUrlParams(url) {
  // get query string from url (optional) or window
  var queryString = url ? url.split("?")[1] : window.location.search.slice(1);

  // we'll store the parameters here
  var obj = {};

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split("#")[0];

    // split our query string into its component parts
    var arr = queryString.split("&");

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split("=");

      // in case params look like: list[]=thing1&list[]=thing2
      var paramNum = undefined;
      var paramName = a[0].replace(/\[\d*\]/, function (v) {
        paramNum = v.slice(1, -1);
        return "";
      });

      // set parameter value (use 'true' if empty)
      var paramValue = typeof a[1] === "undefined" ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      paramValue = paramValue.toLowerCase();

      // if parameter name already exists
      if (obj[paramName]) {
        // convert value to array (if still string)
        if (typeof obj[paramName] === "string") {
          obj[paramName] = [obj[paramName]];
        }
        // if no array index number specified...
        if (typeof paramNum === "undefined") {
          // put the value on the end of the array
          obj[paramName].push(paramValue);
        }
        // if array index number specified...
        else {
          // put the value at that index number
          obj[paramName][paramNum] = paramValue;
        }
      }
      // if param name doesn't exist yet, set it
      else {
        obj[paramName] = paramValue;
      }
    }
  }

  return obj;
}

// Tabs v2
window.initTabs = initTabs;
function initTabs() {
  $(".accordion-tabs-minimal").each(function (index) {
    if ($(this).attr("data-deep-link") == "on") {
      let hash = window.location.hash.substr(1);
      if (hash.length > 0) {
        if (activateTabByHref($(this), hash)) {
          return;
        }
      }
      let urlParams = new URLSearchParams(window.location.search);
      let tabParam = urlParams.get("tab");
      if (tabParam != null) {
        if (activateTabByHref($(this), tabParam)) {
          return;
        }
      }
      if (hash.startsWith("tab") && hash.length > 3) {
        let tabSubstr = hash.substr(3);
        let tab = parseInt(tabSubstr);
        if (!isNaN(tab)) {
          if (activateTabByIndex($(this), tab)) {
            return;
          }
        }
      }

      if (tabParam != null) {
        let tab = parseInt(tabParam);
        if (!isNaN(tab)) {
          if (activateTabByIndex($(this), tab)) {
            return;
          }
        }
      }
    }
    var firstTab = $(this).children("li").first().children("a");
    firstTab.addClass("is-active").next().addClass("is-open").show();
    loadLazyAccordionTabContent(firstTab);
  });
  $(".accordion-tabs-minimal").on("click", "li > a.tab-link", function (event) {
    if (!$(this).hasClass("is-active")) {
      event.preventDefault();
      var accordionTabs = $(this).closest(".accordion-tabs-minimal");
      accordionTabs.find(".is-open").removeClass("is-open").hide();

      $(this).next().toggleClass("is-open").toggle();
      accordionTabs.find(".is-active").removeClass("is-active");
      $(this).addClass("is-active");

      loadLazyAccordionTabContent($(this));

      let href = $(this).attr("href");
      if (href && href[0] == "#") {
        history.replaceState(null, "", href);
      } else {
        history.replaceState(null, "", "#tab" + ($(this).parent().index() + 1));
      }
    } else {
      event.preventDefault();
    }
  });
}

function activateTabByHref(that, tabHref) {
  let a = that.children("li").children("a[href='#" + tabHref + "']");
  if (a.length) {
    $(".tab-link").removeClass("is-active"); // remove active class from all tabs
    a = a.first();
    a.next().toggleClass("is-open").show();
    a.addClass("is-active");

    console.log("Set active tab by href");
    loadLazyAccordionTabContent(a);
    return true;
  }
  return false;
}

function activateTabByIndex(that, tab) {
  let a = that
    .children("li")
    .eq(tab - 1)
    .children("a");
  if (a.length) {
    a.next().toggleClass("is-open").show();
    a.addClass("is-active");

    console.log("Set active tab");
    loadLazyAccordionTabContent(a);
    return true;
  }
  return false;
}

window.loadLazyAccordionTabContent = loadLazyAccordionTabContent;
function loadLazyAccordionTabContent(tab) {
  let load_url = tab.attr("data-load-on-open");
  console.log(load_url);
  if (tab.attr("data-loaded") != "true" || tab.attr("data-clear-siblings") != undefined) {
    tab.attr("data-loaded", true);
    if (load_url != null) {
      tab.next().html('<div class="loading-indicator"><img src="/images/loading.gif"></div>');
      $.ajax({
        url: load_url,
      })
        .done(function (data) {
          console.log("Refreshed a DOM");
          tab.next().html(data);
          // initJS()
        })
        .fail(function (data) {
          tab.next().html('<h1 class="text-lg flex flex-wrap gap-xxxs items-center padding-right-lg"><div><svg class="block icon icon--md" viewBox="0 0 48 48" aria-hidden="true"><path d="M46.019,38.447,26.566,6.309a3,3,0,0,0-5.132,0L1.982,38.447A3,3,0,0,0,4.548,43h38.9a3,3,0,0,0,2.568-4.553Z" opacity="0.5" fill="var(--color-warning)"/><g fill="var(--color-contrast-higher)"><polygon points="25.757 16.182 25.481 31.725 22.53 31.725 22.252 16.182 25.757 16.182"/><circle cx="24" cy="36" r="2"/></g></svg></div><span>Error</span></h1>');
        });
    }
  }

  if (tab.attr("data-clear-siblings") != undefined) {
    console.log("Clearing siblings");
    $(tab).next().html('<div class="loading-indicator"><img src="/images/loading.gif"></div>');
  }
}

$(document).on("change", "#user_public_saves", function () {
  $(this).parents("form").find('input[type="submit"]').click();
});

document.addEventListener("DOMContentLoaded", function () {
  initLazyBackgrounds();
});

function initLazyBackgrounds() {
  var lazyBackgrounds = [].slice.call(document.querySelectorAll(".lazy-background"));

  if ("IntersectionObserver" in window) {
    let lazyBackgroundObserver = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          console.log("Lazy");
          entry.target.classList.add("visible");
          lazyBackgroundObserver.unobserve(entry.target);
        }
      });
    });

    lazyBackgrounds.forEach(function (lazyBackground) {
      lazyBackgroundObserver.observe(lazyBackground);
    });
  }
}

$(document).on("change", "select[data-go-to]", function () {
  window.location.href = $(this).val();
});

// $(document).on('click', '[data-table-filter]+label', function(){

// });
$(document).on("input", "[data-table-filter]", function () {
  console.log("updateFilteredContent on input");
  // check if has attribute "data-delayed"
  if ($(this).attr("data-delayed") != undefined) {
    console.log("Delayed update");
    setTimeout(() => {
      updateFilteredContent();
    }, 20);
  } else {
    console.log("Instant update");
    updateFilteredContent();
  }
});
$(document).on("change", "select[data-table-filter]", function () {
  console.log("updateFilteredContent on change");
  updateFilteredContent();
});

window.updateFilteredContent = updateFilteredContent;
function updateFilteredContent() {
  // $.doTimeout( 'filterOnInput', 400, function(){
  if (typeof updateTable === "function") {
    updateTable();
  }

  // setUpTabFilters()
  // return false;
  // });
}

// function setUpTabFilters(then_load = true) {
// 	var queryParams = {}
// 	$('[data-table-filter]').each(function(){
// 		var value = $(this).attr('data-table-filter')
// 		queryParams[value] = $(this).val()
// 	})
// }

$(document).on("change", ".filter-item input[data-single-select]", function () {
  // uncheck all filters
  $(".filter-item input[data-single-select]").prop("checked", false);
  // check the one that was clicked
  $(this).prop("checked", true);
});

function categorySearch() {}

$(document).on("click", "[data-affiliate-open]", function (e) {
  e.preventDefault();
  var url = $(this).attr("href");
  var affiliate_url = $(this).data("affiliate-open");
  console.log(affiliate_url);
  console.log(url);
  if (url != undefined) {
    window.open(url, "_blank");
  }
  if (affiliate_url != undefined) {
    window.location.href = affiliate_url;
  }
});

$(document).on("click", "[data-affiliate-open-mobile]", function (e) {
  if ($(window).width() <= 768) {
    e.preventDefault();
    var affiliate_url = $(this).data("affiliate-open-mobile");
    if (affiliate_url != undefined) {
      window.location.href = affiliate_url;
    }
  }
});

$(document).on("click", ".offer-stripe.tappable", function (e) {
  if ($(window).width() <= 768) {
    e.preventDefault();
    window.open($(this).attr("href"));
  }
});

function initHorizontaScroll() {
  const slider = document.querySelector(".hs-items");
  let isDown = false;
  let startX;
  let scrollLeft;

  if (slider != undefined) {
    slider.addEventListener("mousedown", (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mouseup", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 2; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
      console.log(walk);
    });
  }
}

function showOffer(offerID) {
  $.fancybox.open({
    src: "/offers/" + offerID + "/modal",
    type: "ajax",
    afterShow: function () {
      initFancybox();
    },
  });
}
function showAllCoupons(store_id) {
  $.fancybox.open({
    src: "/stores/" + store_id + "/all_coupons",
    type: "ajax",
    afterShow: function () {
      initFancybox();
    },
  });
}
function showStoreSignup(store_id) {
  if ($("#modal-sign-up").length > 0) {
    $.fancybox.open({
      src: "#modal-sign-up",
      modal: false,
      afterShow: function () {
        initFancybox();
      },
    });
  }
}

$(document).on("select2:open", (e) => {
  console.log("select2:open");
  setTimeout(function () {
    document.querySelector(".select2-container--open .select2-search__field").focus();
    // timeout fixed - opening a new select2 while another is still open
  }, 10);
});

window.initFancybox = initFancybox;
function initFancybox() {
  console.log("initFancybox");
  $("[data-fancybox]").fancybox({
    // modal: true,
    touch: false,
    smallBtn: true,
    beforeShow: function (instance, slide) {
      $(".sliding-menu-content,.sliding-menu-fade-screen").removeClass("is-visible");
    },
    afterShow: function (instance, current) {
      console.info(current.$slide);
      var source = current.opts.$orig.data("source");
      if (source !== undefined) {
        current.$slide.find(".signup-source").val(source);
        document.cookie = "sign_up_source=" + source + ";path=/";
      }
      // initSelect2();
    },
  });
}

window.closeModal = closeModal;
function closeModal() {
  if (parent.jQuery.fancybox.getInstance()) {
    parent.jQuery.fancybox.getInstance().close();
  }
}
window.closeAllModals = closeAllModals;
function closeAllModals() {
  closeModal();
  closeModal();
  closeModal();
  closeModal();
  // ikr
}
