export function isMobile() {
    const isAppleMobileDevice = () => {
        if (navigator?.platform?.match(/iPhone/)) {
            return 'iPhone';
        } else if (
            (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) ||
            navigator?.platform?.match(/iPad/)
        ) {
            return 'iPad';
        } else return false;
    };

    const isAndroid =
        navigator?.userAgent?.toLowerCase()?.indexOf('android') !== -1
            ? 'Android'
            : false;
    return isAppleMobileDevice() || isAndroid;
}

export const getBrowserinfo = () => {
    if (typeof navigator === 'undefined') return;
    let userAgent = navigator?.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = 'chrome';
    } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = 'firefox';
    } else if (userAgent.match(/safari/i)) {
        browserName = 'safari';
    } else if (userAgent.match(/opr\//i)) {
        browserName = 'opera';
    } else if (userAgent.match(/edg/i)) {
        browserName = 'edge';
    } else {
        browserName = 'No browser detection';
    }
    return browserName;
};

export const getAllDeviceInfo = () => {
    return {
        manufacturer:
            typeof window !== 'undefined' || typeof navigator !== 'undefined'
                ? navigator?.vendor
                : '',
        model: typeof isMobile() === 'string' ? isMobile() : 'desktop',
        name: getBrowserinfo() || '',
        type: 'browser',
        //@ts-ignore
        version: navigator?.userAgentData?.brands[0]?.version || '',
    };
};

export const isSafari =
    getAllDeviceInfo().type === 'browser' && getAllDeviceInfo().name === 'safari';

export const isSafariIPhone = isSafari && getAllDeviceInfo().model === 'iPhone';
// true;

export const isSafariDesktop =
    isSafari && getAllDeviceInfo().model === 'desktop';
// true;

export const isSafariIpad = isSafari && getAllDeviceInfo().model === 'iPad';

export const isSafariMobile = isSafariIpad || isSafariIPhone;
