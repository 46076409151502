import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "banner", "pageContent" ]

  connect() {
    if ( document.querySelector("section#banner") && window.location.href.includes("#refer") ) {
      this.showTabReferContent()
    }
  }

  showTabShopContent() {
    this.bannerTarget.querySelectorAll(".tab-shop-content").forEach(function(shopContent) {
      shopContent.classList.remove("hide")
    })

    this.bannerTarget.querySelectorAll(".tab-refer-content").forEach(function(referContent) {
      referContent.classList.add("hide")
    })
  }

  showTabReferContent() {
    this.bannerTarget.querySelectorAll(".tab-shop-content").forEach(function(shopContent) {
      shopContent.classList.add("hide")
    })

    this.bannerTarget.querySelectorAll(".tab-refer-content").forEach(function(referContent) {
      referContent.classList.remove("hide")
    })
  }

  closeBanner() {
    this.bannerTarget.style.display = "none"

    if ( screen.width >= 1368 ) {
      this.pageContentTarget.classList.add("mx-auto")
    }
  }
}
