// Example usage:
// <div data-controller="tooltip" data-tippy-content="Hello world"></div>

import { Controller } from "@hotwired/stimulus"
import tippy, {roundArrow} from "tippy.js";

export default class extends Controller {
  static values = {
    content: String
  }

  connect() {
    let options = {}
    if (this.hasContentValue) {
      options['content'] = this.contentValue
    }
    options['animation'] = "shift-away-subtle"
    options['arrow'] = roundArrow
    options['touch'] = this.element.getAttribute('data-touch') == 'false' ? false : true

    // if has [data-template] attribute, use the template as the content
    if (this.element.hasAttribute('data-template')) {
      this.tippy = tippy(this.element, {
        content(reference) {
          const id = reference.getAttribute('data-template');
          const template = document.getElementById(id);
          return template.innerHTML;
        },
        allowHTML: true,
        interactive: true,
      })
      
    } else {
      this.tippy = tippy(this.element, options);
    }
  }

  disconnect() {
    this.tippy.destroy();
  }
}


 